<template>
  <v-row>
    <v-col cols="12">
      <v-textarea v-if="$vuetify.breakpoint.mobile"
                  dense
                  rows="3"
                  label="* Descricao"
                  :rules="[
                      this.$validators.string.required
                  ]"
                  v-model.trim="value.descricao"/>
      <v-text-field v-else label="* Descricao"
                    dense
                    :rules="[
                      this.$validators.string.required
                  ]"
                    v-model.trim="value.descricao"/>
    </v-col>
    <v-col cols="12">
      <lookup-dominio label="* Local"
                      hint="Selecione qual o local dessa quadra"
                      v-model="value.local"
                      :type="lookupType"
                      :rules="[
                      this.$validators.string.required
                  ]"/>
    </v-col>
    <v-col cols="12">
      <v-text-field label="* Qtd. Alunos"
                    dense
                    type="number"
                    :rules="[
                      this.$validators.notNullOrUndefined,
                      v => this.$validators.number.greaterThanOrEquals(v, 1, 'Deve ter no minimo 1 aluno.'),
                      v => this.$validators.number.integerOnly(v, 'O numero de alunos deve ser exato'),
                  ]"
                    v-model="value.qtdAluno"/>
    </v-col>
  </v-row>
</template>

<script>
import LookupDominio from "../common/LookupDominio";
import {ItemDeDominio} from "../../../api/itemsDominio";

export default {
  name: "QuadraForm",
  components: {LookupDominio},
  props: {
    value: {
      type: Object
    }
  },
  data() {
    return {
      lookupType: ItemDeDominio.LOCAL_QUADRA
    }
  }
}
</script>

<style scoped>

</style>