var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.$vuetify.breakpoint.mobile)?_c('v-textarea',{attrs:{"dense":"","rows":"3","label":"* Descricao","rules":[
                    this.$validators.string.required
                ]},model:{value:(_vm.value.descricao),callback:function ($$v) {_vm.$set(_vm.value, "descricao", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.descricao"}}):_c('v-text-field',{attrs:{"label":"* Descricao","dense":"","rules":[
                    this.$validators.string.required
                ]},model:{value:(_vm.value.descricao),callback:function ($$v) {_vm.$set(_vm.value, "descricao", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.descricao"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('lookup-dominio',{attrs:{"label":"* Local","hint":"Selecione qual o local dessa quadra","type":_vm.lookupType,"rules":[
                    this.$validators.string.required
                ]},model:{value:(_vm.value.local),callback:function ($$v) {_vm.$set(_vm.value, "local", $$v)},expression:"value.local"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"* Qtd. Alunos","dense":"","type":"number","rules":[
                    this.$validators.notNullOrUndefined,
                    function (v) { return this$1.$validators.number.greaterThanOrEquals(v, 1, 'Deve ter no minimo 1 aluno.'); },
                    function (v) { return this$1.$validators.number.integerOnly(v, 'O numero de alunos deve ser exato'); } ]},model:{value:(_vm.value.qtdAluno),callback:function ($$v) {_vm.$set(_vm.value, "qtdAluno", $$v)},expression:"value.qtdAluno"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }