<template>
  <base-view
      @click:add="criar"
      @update:search-values="updateSearch"
      :search-columns="searchColumns">
    <create-edit-quadra-dialog :item="item"
                               v-model="editDialogOpened"
                               @onSave="() => $refs.dataTable.refresh()"/>

    <div>
      <quadras-data-table ref="dataTable"
                          @click:edit="editar"
                          @click:remove="remover"
                          :search-values="searchValues"/>
    </div>
  </base-view>

</template>

<script>
import QuadrasDataTable from "../../components/shared/datatables/QuadrasDataTable";
import BaseView from "../BaseView";
import {findDisponibilidade, remove} from "../../api/quadras";
import CreateEditQuadraDialog from "../../components/shared/dialogs/CreateEditQuadraDialog";
import {ItemDeDominio} from "../../api/itemsDominio";
import {mapActions} from "vuex";

export default {
  name: "index",
  components: {CreateEditQuadraDialog, BaseView, QuadrasDataTable},
  data() {
    return {
      item: null,
      editDialogOpened: false,
      selectedItems: [],
      searchValues: {},
      searchColumns: [
        // {
        //   name: 'ID',
        //   column: 'id',
        //   type: 'number',
        //   prefix: '#'
        // },
        {
          name: 'Descricao',
          column: 'descricao',
          type: 'text'
        },
        {
          name: 'Local',
          column: 'local',
          type: 'select',
          optionsFactory: () => this.fetch({
            itemDominio: ItemDeDominio.LOCAL_QUADRA
          })
        }
      ]
    }
  },
  methods: {
    ...mapActions('itemsDominio', ['fetch']),
    updateSearch(search) {
      this.searchValues = search;
    },
    criar() {
      this.item = {};
      this.editDialogOpened = true;
    },
    editar(aula) {
      this.item = JSON.parse(JSON.stringify(aula));
      this.editDialogOpened = true;
    },
    async remover({id}) {
      try {
        await remove(id);
        this.$toast.success(`Quadra removida com sucesso.`, {
          timeout: 3000
        });
        this.$refs.dataTable.refresh();
      } catch (err) {
        this.$toast.error(`Não foi possível remover à Quadra.`, {
          timeout: 6000
        })
      }
    }
  },
}
</script>

<style scoped lang="scss">


</style>